<template>
  <div v-show="show"
       class="flex flex-col shrink-0 grow flex-wrap p-2 overflow-y-auto border-2 
             border-neutral-100 hover:shadow-xl"
       :class="{'basis-1/2': !full_screen, 
                'min-h-[50%]': !full_screen,
                'max-h-[50%]': !full_screen,
                'basis-1': full_screen,
                'h-full': full_screen}">
    <div>
      <div class="relative top-0 h-6 w-full z-50"
            style="font-variation-settings:  'wght' 700, 'GRAD' 0, 'opsz' 48;"
            id="table-expander">
        <span class="font-symbol material-symbols-outlined w-6 float-right
                      bg-white"
              v-show="!full_screen"
              @click="full_screen = true">fullscreen</span> 
        <span class="font-symbol material-symbols-outlined w-6 float-right"
              v-show="full_screen"
              @click="full_screen = false">fullscreen_exit</span> 
      </div>
      <div v-if="data_available"
           class="mx-auto h-max mb-2 -mt-2 w-full text-center text-xl 
                  font-bold">Change Summary</div>
      <div v-if="data_available" class="w-full px-2">
        <p class="font-semibold">Largest land cover type in 2020:</p>
        <p class="pl-3 pt-0.5">{{  max_cover[0] }} {{ max_cover[1] }} acres 
        ({{ max_cover[2] }}%).</p>
      </div>
      <div v-if="data_available" class="w-full py-4 px-2">
        <p class="font-semibold">Largest <span class="italic font-bold"
          >change</span> (1986 - 2020) in land cover type :</p>
        <p class="pl-3 pt-0.5">{{  max_change[0] }} {{ max_change[1] }} acres, 
         ({{ max_change[2] }}% {{ parseFloat( max_change[1]) > 0?
        'increase':decrease }}).</p>
      </div>

      <div v-if="data_available" class="w-full">
        <div class="text-base font-semibold underline w-full text-center"
        >Forest</div>
        <div class="w-full grid grid-cols-12 text-neutral-700">
          <span class="col-span-7 text-right pr-2">Total 
            {{ change.forest[0][0] >= 0?'gain':'loss' }} 1986 to 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ 
            parseFloat((Math.round(10 * change.forest[0][0])/10)
            .toFixed(0)).toLocaleString()}} acres</span>

          <span class="col-span-7 text-right pr-2">Total 
            Forest change rank:
          </span>
          <span class="col-span-5 text-green-700">{{ change.forest[3] }} of
          564. </span>


          <span class="col-span-7 text-right pr-2">% of total area 1986 
            <span class=" text-neutral-700"> || </span> 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ change.forest[1][0] 
          }} <span class=" text-neutral-700"> || </span>  
          {{ change.forest[1][1] }}</span>
          <span class="col-span-7 text-right flex justify-end pr-2">
            <span class="text-center"><p>Greatest change:</p>
            </span>
          </span>
          <span class="col-span-5 text-green-700">
            {{ parseFloat(change.forest[2][0].toFixed(0)).toLocaleString() }}
            acres ({{ change.forest[2][1] }})
          </span>
        </div>

        <div class="text-base font-semibold underline w-full text-center"
        >Agriculture</div>
        <div class="w-full grid grid-cols-12 text-neutral-700">
          <span class="col-span-7 text-right pr-2">Total 
            {{ change.agriculture[0][0] >= 0?'gain':'loss' }} 1986 to 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ 
            parseFloat((Math.round(10 * change.agriculture[0][0])/10)
            .toFixed(0)).toLocaleString()}} acres</span>
          <span class="col-span-7 text-right pr-2">% of total area 1986 
            <span class=" text-neutral-700"> || </span> 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ change.agriculture[1][0] 
          }} <span class=" text-neutral-700"> || </span>  
          {{ change.agriculture[1][1] }}</span>
          <span class="col-span-7 text-right flex justify-end pr-2">
            <span class="text-center"><p>Greatest change:</p>
            </span>
          </span>
          <span class="col-span-5 text-green-700">
            {{ parseFloat(change.agriculture[2][0].toFixed(0)).toLocaleString() 
            }} acres ({{ change.agriculture[2][1] }})
          </span>
        </div>

        <div class="text-base font-semibold underline w-full text-center"
        >Urban</div>
        <div class="w-full grid grid-cols-12 text-neutral-700">
          <span class="col-span-7 text-right pr-2">Total 
            {{ change.urban[0][0] >= 0?'gain':'loss' }} 1986 to 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ 
            parseFloat((Math.round(10 * change.urban[0][0])/10)
            .toFixed(0)).toLocaleString()}} acres</span>
          <span class="col-span-7 text-right pr-2">% of total area 1986 
            <span class=" text-neutral-700"> || </span> 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ change.urban[1][0] 
          }} <span class=" text-neutral-700"> || </span>  
          {{ change.urban[1][1] }}</span>
          <span class="col-span-7 text-right flex justify-end pr-2">
            <span class="text-center"><p>Greatest change:</p>
            </span>
          </span>
          <span class="col-span-5 text-green-700">
            {{ parseFloat(change.urban[2][0].toFixed(0)).toLocaleString() }}
            acres ({{ change.urban[2][1] }})
          </span>
        </div>

        <div class="text-base font-semibold underline w-full text-center"
        >Wetland</div>
        <div class="w-full grid grid-cols-12 text-neutral-700">
          <span class="col-span-7 text-right pr-2">Total 
            {{ change.wetland[0][0] >= 0?'gain':'loss' }} 1986 to 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ 
            parseFloat((Math.round(10 * change.wetland[0][0])/10)
            .toFixed(0)).toLocaleString()}} acres</span>
          <span class="col-span-7 text-right pr-2">% of total area 1986 
            <span class=" text-neutral-700"> || </span> 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ change.wetland[1][0] 
          }} <span class=" text-neutral-700"> || </span>  
          {{ change.wetland[1][1] }}</span>
          <span class="col-span-7 text-right flex justify-end pr-2">
            <span class="text-center"><p>Greatest change:</p>
            </span>
          </span>
          <span class="col-span-5 text-green-700">
            {{ parseFloat(change.wetland[2][0].toFixed(0)).toLocaleString() }}
            acres ({{ change.wetland[2][1] }})
          </span>
        </div>

        <div class="text-base font-semibold underline w-full text-center"
        >Water</div>
        <div class="w-full grid grid-cols-12 text-neutral-700">
          <span class="col-span-7 text-right pr-2">Total 
            {{ change.water[0][0] >= 0?'gain':'loss' }} 1986 to 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ 
            parseFloat((Math.round(10 * change.water[0][0])/10)
            .toFixed(0)).toLocaleString()}} acres</span>
          <span class="col-span-7 text-right pr-2">% of total area 1986 
            <span class=" text-neutral-700"> || </span> 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ change.water[1][0] 
          }} <span class=" text-neutral-700"> || </span>  
          {{ change.water[1][1] }}</span>
          <span class="col-span-7 text-right flex justify-end pr-2">
            <span class="text-center"><p>Greatest change:</p>
            </span>
          </span>
          <span class="col-span-5 text-green-700">
            {{ parseFloat(change.water[2][0].toFixed(0)).toLocaleString() }}
            acres ({{ change.water[2][1] }})
          </span>
        </div>

        <div class="text-base font-semibold underline w-full text-center"
        >Barren</div>
        <div class="w-full grid grid-cols-12 text-neutral-700">
          <span class="col-span-7 text-right pr-2">Total 
            {{ change.barren[0][0] >= 0?'gain':'loss' }} 1986 to 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ 
            parseFloat((Math.round(10 * change.barren[0][0])/10)
            .toFixed(0)).toLocaleString()}} acres</span>
          <span class="col-span-7 text-right pr-2">% of total area 1986 
            <span class=" text-neutral-700"> || </span> 2020: 
          </span>
          <span class="col-span-5 text-green-700">{{ change.barren[1][0] 
          }} <span class=" text-neutral-700"> || </span>  
          {{ change.barren[1][1] }}</span>
          <span class="col-span-7 text-right flex justify-end pr-2">
            <span class="text-center"><p>Greatest change:</p>
            </span>
          </span>
          <span class="col-span-5 text-green-700">
            {{ parseFloat(change.barren[2][0].toFixed(0)).toLocaleString() }}
            acres ({{ change.barren[2][1] }})
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
                
    name: 'MiscellaneousComponent',

    props:[
      'lulc',
      'lulc_key',
      'lulc_version',
      'selection',
      'visible'
    ],
            
    emits: [
      'full_screen',
    ],

    watch:{
      full_screen: function(){
        this.$emit('full_screen', this.full_screen, this.$.type.name);
      },

      selection: {
        deep: true,
        handler(){
          if(!this.selection){
            this.data_available = false;
            return;
          }
          if(!this.selection.id){
            this.data_available = false;
            return;
          }
          const arr = this.lulc.filter(({ type }) => type === 
                      this.selection.geography.substring(0, 1));

          this.stats = arr.filter(({ id }) => id === 
                                                  parseInt(this.selection.id));
          this.processStats();
        }
      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      }
    },

    data(){
      return{
        change: {
          'forest': [0,0,0,0],
          'agriculture': [0,0,0],
          'water': [0,0,0],
          'wetland': [0,0,0],
          'barren': [0,0,0],
          'urban': [0,0,0],
        },
        data_available: false,
        full_screen: false,
        max_cover: ['', 0],
        max_change: ['', 0],
        show: true,
        stats: null
      }
    },

    methods:{

      processStats(){
        let vals = [];
        let d = this.stats[0];
        let max_index = null;
        let max_cover = ['', 0];
        let max_change = ['', 0];
        let abs = null;
        const interval = ['86 to 95', '95 to 02', '02 to 07', 
                          '07 to 12', '12 to 15', '15 to 20'];
        

        vals = [d['f43'], d['f44'], d['f45'], d['f46'], d['f47'], d['f48']];
        abs = vals.map((x) =>  Math.abs(x));
        max_index = abs.indexOf(Math.max(...abs));
        this.change.forest[0] = [d['f49']];
        this.change.forest[1] = [(Math.round(1000 * d['f85'])/10).toFixed(1) +
                        '%', (Math.round(1000 * d['f121'])/10).toFixed(1) +'%'];
        this.change.forest[2] = [vals[max_index], interval[max_index]];
        this.change.forest[3] = d['c6'];
        max_cover = ['Forest', d['f7'], d['f121']]
        max_change = ['Forest', d['f49'], (d['f1'] - d['f7'])/d['f1']];

        vals = [d['f50'], d['f51'], d['f52'], d['f53'], d['f54'], d['f55']];
        abs = vals.map((x) =>  Math.abs(x));
        max_index = abs.indexOf(Math.max(...abs));
        this.change.agriculture[0] = [d['f56']];
        this.change.agriculture[1] = [(Math.round(1000 * d['f86'])/10).toFixed(1) 
        +'%', (Math.round(1000 * d['f122'])/10).toFixed(1) +'%'];
        this.change.agriculture[2] = [vals[max_index], interval[max_index]];
        if(d['f14'] > max_cover[1]){
          max_cover = ['Agriculture', d['f14'], d['f122']];
        }
        if(d['f56'] > max_change[1]){
          max_change = ['Agriculture', d['f56'], (d['f8'] - d['f14'])/d['f8']];
        }

        vals = [d['f57'], d['f58'], d['f59'], d['f69'], d['f61'], d['f62']];
        abs = vals.map((x) =>  Math.abs(x));
        max_index = abs.indexOf(Math.max(...abs));
        this.change.water[0] = [d['f63']];
        this.change.water[1] = [(Math.round(1000 * d['f87'])/10).toFixed(1) +'%', 
        (Math.round(1000 * d['f123'])/10).toFixed(1) +'%'];
        this.change.water[2] = [vals[max_index], interval[max_index]];
        if(d['f21'] > max_cover[1]){
          max_cover = ['Water', d['f21'], d['f123']];
        }
        if(Math.abs(d['f63']) > Math.abs(max_change[1])){
          max_change = ['Water', d['f63'], (d['f15'] - d['f21'])/d['f15']];
        }

        vals = [d['f64'], d['f65'], d['f66'], d['f67'], d['f68'], d['f69']];
        abs = vals.map((x) =>  Math.abs(x));
        max_index = abs.indexOf(Math.max(...abs));
        this.change.barren[0] = [d['f70']];
        this.change.barren[1] = [(Math.round(1000 * d['f88'])/10).toFixed(1) + 
        '%', (Math.round(1000 * d['f124'])/10).toFixed(1) +'%'];
        this.change.barren[2] = [vals[max_index], interval[max_index]];
        if(d['f28'] >  max_cover[1]){
          max_cover = ['Barren', d['f28'], d['f124']];
        }
        if(Math.abs(d['f70']) > Math.abs(max_change[1])){
          max_change = ['Barren', d['f70'], (d['f22'] - d['f28'])/d['f22']];
        }

        vals = [d['f71'], d['f72'], d['f73'], d['f74'], d['f75'], d['f76']];
        abs = vals.map((x) =>  Math.abs(x));
        max_index = abs.indexOf(Math.max(...abs));
        this.change.wetland[0] = [d['f77']];
        this.change.wetland[1] = [(Math.round(1000 * d['f89'])/10).toFixed(1) +
        '%', (Math.round(1000 * d['f125'])/10).toFixed(1) +'%'];
        this.change.wetland[2] = [vals[max_index], interval[max_index]];
        this.data_available = true;
        if(d['f35'] >  max_cover[1]){
          max_cover = ['Wetland', d['f35'], d['f125']];
        }
        if(Math.abs(d['f77']) > Math.abs(max_change[1])){
          max_change = ['Wetland', d['f77'], (d['f29'] - d['f35'])/d['f29']];
        }


        vals = [d['f78'], d['f79'], d['f80'], d['f81'], d['f82'], d['f83']];
        abs = vals.map((x) =>  Math.abs(x));
        max_index = abs.indexOf(Math.max(...abs));
        this.change.urban[0] = [d['f84']];
        this.change.urban[1] = [(Math.round(1000 * d['f90'])/10).toFixed(1) +
        '%', (Math.round(1000 * d['f126'])/10).toFixed(1) +'%'];
        this.change.urban[2] = [vals[max_index], interval[max_index]];
        this.data_available = true;
        if(d['f42'] >  max_cover[1]){
          max_cover = ['Urban', d['f42'], d['f126']];
        }
        if(Math.abs(d['f84']) > Math.abs(max_change[1])){
          max_change = ['Urban', d['f84'], (d['f36'] - d['f42'])/d['f36']];
        }
        max_cover[1] = parseFloat((Math.round(10 * max_cover[1])/10).toFixed(1)).toLocaleString();   
        // max_change[1] = parseFloat((Math.round(10 * -max_change[1])/10).toFixed(1)).toLocaleString();
        max_cover[2] = (Math.round(1000 * max_cover[2])/10).toFixed(1);   
        max_change[2] = (Math.round(1000 * -max_change[2])/10).toFixed(1);
        max_change[1] = parseFloat(max_change[1].toFixed(1)).toLocaleString();
        this.max_change = max_change;
        this.max_cover = max_cover;
      }
    }
  }

</script>