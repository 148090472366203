<template>
  <div v-show="show"
       class="flex shrink-0 grow flex-wrap overflow-hidden border-2 border-neutral-50 hover:shadow-xl"
       :class="{'basis-1/2': !full_screen,
                'min-h-[50%]': !full_screen,
                'max-h-[50%]': !full_screen,
                'basis-1': full_screen,
                'h-full': full_screen}">
    <div class="absolute top-3 right-0 mr-2 h-8 w-8 z-50 bg-white border-2 border-gray-300 rounded-lg"
         style="font-variation-settings:  'wght' 700, 'GRAD' 0, 'opsz' 48; font-size: 48px"
         id="map-expander">
      <span class="font-symbol material-symbols-outlined w-full text-center align-middle pb-0.5"
            v-show="!full_screen"
            @click="full_screen = true">fullscreen</span> 
      <span class="font-symbol material-symbols-outlined w-full text-center align-middle pb-0.5"
            v-show="full_screen"
            @click="full_screen = false">fullscreen_exit</span> 
    </div>
    <div id="map" class="w-full h-full z-40" v-show="show"></div>
    <OverlayComponent
      v-bind:basemap_change="basemap_change"
      v-bind:feature_opacity="feature_opacity"
      v-bind:lulc_codes="lulc_codes"
      v-bind:lulc_version="lulc_version"
      v-bind:map="map"
      v-bind:selected_overlay="selected_overlay"
      v-bind:selection="selection"
      v-bind:popup="popup"
      />
  </div>
</template>

<script>

    import 'mapbox-gl/dist/mapbox-gl.css';
    import mapboxgl from 'mapbox-gl';
    import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
    import "mapbox-gl-style-switcher/styles.css";
    import OpacityControl from "./OpacityControl.vue" 
    import OverlayComponent from "./OverlayComponent.vue" 

    export default {
                
      name: 'MapComponent',

      components:{
        OverlayComponent
      },

      props: [
        'lulc_codes',
        'lulc_version',
        'resize_map',
        'selected_overlay',
        'selection',
        'visible'
      ],

      data(){
          return{
            basemap_change: false,
            control_list: [
                [new MapboxStyleSwitcherControl(null,{eventListeners: 
                  {onChange: ()=>this.handleBasemapChange()}}), 
                                              'top-right', 'xs', 'style'],
                [new mapboxgl.NavigationControl(),
                                          'top-right', 'md', 'navigation'],

                [new mapboxgl.AttributionControl({
                    customAttribution: 
                      `<span 
                      title="Site Designed and Created by Mark Corbalis">
              &nbsp;&nbsp;&nbsp;
              <a href="https://www.linkedin.com/in/mark-corbalis-325b87292"
                  target="_blank">Mark Corbalis</a>&nbsp;</span>`
                  }),'bottom-right', 'xs', 'attribution'],
                [new mapboxgl.FullscreenControl(
                        {container: document.querySelector('body')}),
                                          'top-right', 'md', 'fullscreen'],
                [new OpacityControl('Opacity', '1'), 'bottom-right', 
                                    'xs', 'opacity'],
                [new mapboxgl.ScaleControl({unit: 'imperial'}),
                                            'bottom-right', 'md', 'scale'],
            ],
            default_style: 'mapbox://styles/mapbox/light-v10',
            default_view: {
                'lat': 40.0790,
                'lng': -75.8510,
                'lat_small_screen': 40.1,
                'lng_small_screen': -74.56625054468779,
                'zoom': 6.4,
                'bearing': 0,
                'pitch': 10},
            feature_opacity: .8,
            full_screen: false,
            map: null,
            mapbox_token: 'pk.eyJ1IjoibWNvcmJhbGlzIiwiYSI6ImNpc2I1dnczaD'
                        + 'AwMTgyb3FvbTFyZ3BuY3QifQ.HOUyxTBQQDeZa748snMQzw',
            popup: null,
            show: true,
            small_screen: true
          }
      },
        
      emits: [
        'full_screen',
        'map',
        'map_loaded',
        'popup'
      ],

      watch:{
        full_screen: {
          immediate: true,
          handler(newVal) {
            this.setControlVisibility(newVal);
            this.$emit('full_screen', this.full_screen, this.$.type.name);
          }
        },

        selection: {
          deep: true,
          handler(){
            if(!this.selection){
              return;
            }else if(!this.selection.id){
              this.setDefaultMapView();
            }
          }
        },

        visible: {
          deep: true,
          handler(){
            if(this.visible[this.$.type.name]){
              this.show = true;
            }else{
              this.show = false;
            }
            this.map.resize();
          }
        }
      },

      created(){
        mapboxgl.accessToken = this.mapbox_token;
      },

      mounted(){
        this.createMap();
        this.addResizer();
        this.addControls();
        this.createPopup();
      },

      methods: {
        addControls(){
          for (const control of this.control_list) {
            this.map.addControl(control[0], control[1]);
          }
          const el = document
                     .getElementsByClassName('mapboxgl-ctrl-top-right')[0];
          el.classList.add('mt-10');
          document.getElementsByClassName('mapboxgl-style-switcher')[0]
              .setAttribute('title', 'Set background map');
          this.setControlVisibility(false);
          this.addOpacityControlHandler();
        },

        addOpacityControlHandler(){
            const ctl = document.getElementById('opacity-ctl-1');

            ctl.value = this.feature_opacity * 100;

            if(ctl){
              ctl.addEventListener('change', (e) => {
                this.adjustFeatureOpacity(e);
              });
            }
        },

        addResizer(){
          const observer = new ResizeObserver(() => {
            this.map.resize();
          });
          const el = document.getElementById('map');
        
          observer.observe(el);
        },

        adjustFeatureOpacity(e){
          this.feature_opacity = parseInt(e.target.value, 10) / 100;
        },

        createMap(){
          this.map = new mapboxgl.Map({
              container:  'map',
              style: this.default_style,
              antialias: true,
              attributionControl: false,
              ScaleControl: false,
              preserveDrawingBuffer: true,
              minZoom: 6,
              maxZoom: 18
          });
          this.setDefaultMapView();
          this.$emit('map', this.map);
          this.placeMapExpander();

          this.map.on('load', () => {
            this.$emit('map_loaded', true);
          });  
          
          this.map.on('click', () => {
            // console.log(this.map.getStyle().layers);
          });  
        },

        createPopup(){
          this.popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
          });

          this.$emit('popup', this.popup);
        },

        handleBasemapChange(){
          this.basemap_change = !this.basemap_change;
        },

        placeMapExpander(){
          const el = document.querySelector('#map-expander');
          const map = document.querySelector('#map');
          map.prepend(el);
        },

        setControlVisibility(show){
          const visible = show?show:false;
          const ctl = document
                        .getElementsByClassName('mapboxgl-control-container')[0];
            if(!ctl){return;}
            if(visible){
              ctl.classList.remove('hidden')
            }else{
              ctl.classList.add('hidden')
            }
        },

        setMapView(view){
          
            this.map.setZoom(view.zoom);
            this.map.setBearing(view.bearing);
            this.map.setPitch(view.pitch);
            if(this.small_screen){
              this.map.setCenter([view.lng_small_screen, 
                                                  view.lat_small_screen]);
            }else{
              this.map.setCenter([view.lng, view.lat]);
            }
        },

        setDefaultMapView(){
          this.setMapView(this.default_view);

          // if(this.small_screen){
          //   return;
          // }
          // let lng_factor = 1;
          // let lat_factor = 1;
          // let zoom = 7;

          // if(window.innerWidth < this.break_points['md']){
          //   lng_factor = 1;
          //   lat_factor = 1;
          // }else if(window.innerWidth < this.break_points['lg']){
          //   lng_factor = 1.9;
          //   lat_factor = 6;
          // }else if(window.innerWidth < this.break_points['xl']){
          //   lng_factor = 2.3;
          //   lat_factor = 14;
          // }else if(window.innerWidth < this.break_points['xxl']){
          //   lng_factor = 2;
          //   lat_factor = 14;
          //   // zoom=8;
          // }else{
          //   lng_factor = 2;
          //   lat_factor = 14;
          //   zoom=8;
          // }

          // this.map.setZoom(zoom);
          // const bounds = this.map.getBounds().toArray();
          // const lng_diff = (this.default_view.lng - bounds[1][0])/-lng_factor;
          // const lat_diff = (this.default_view.lat - bounds[1][1])/-lat_factor;

          // const lng = this.default_view.lng - lng_diff;
          // const lat = this.default_view.lat - lat_diff;
          // this.map.setCenter([lng, lat]);
        }
      }
    }

</script>

<style>
  .mapboxgl-style-switcher
      /* Vue/Mapbox/Deck upgrade on 11/22 overrode this style in the switcher 
        module.  BG Image corresponds to layer-stack.svg in icons folder.
      */
        { 
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTQuODQ5cHgiIGhlaWdodD0iNTQuODQ5cHgiIHZpZXdCb3g9IjAgMCA1NC44NDkgNTQuODQ5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NC44NDkgNTQuODQ5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PGc+PHBhdGggZD0iTTU0LjQ5NywzOS42MTRsLTEwLjM2My00LjQ5bC0xNC45MTcsNS45NjhjLTAuNTM3LDAuMjE0LTEuMTY1LDAuMzE5LTEuNzkzLDAuMzE5Yy0wLjYyNywwLTEuMjU0LTAuMTA0LTEuNzktMC4zMThsLTE0LjkyMS01Ljk2OEwwLjM1MSwzOS42MTRjLTAuNDcyLDAuMjAzLTAuNDY3LDAuNTI0LDAuMDEsMC43MTZMMjYuNTYsNTAuODFjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMEw1NC40ODgsNDAuMzNDNTQuOTY0LDQwLjEzOSw1NC45NjksMzkuODE3LDU0LjQ5NywzOS42MTR6Ii8+PHBhdGggZD0iTTU0LjQ5NywyNy41MTJsLTEwLjM2NC00LjQ5MWwtMTQuOTE2LDUuOTY2Yy0wLjUzNiwwLjIxNS0xLjE2NSwwLjMyMS0xLjc5MiwwLjMyMWMtMC42MjgsMC0xLjI1Ni0wLjEwNi0xLjc5My0wLjMyMWwtMTQuOTE4LTUuOTY2TDAuMzUxLDI3LjUxMmMtMC40NzIsMC4yMDMtMC40NjcsMC41MjMsMC4wMSwwLjcxNkwyNi41NiwzOC43MDZjMC40NzcsMC4xOSwxLjI1MSwwLjE5LDEuNzI5LDBsMjYuMTk5LTEwLjQ3OUM1NC45NjQsMjguMDM2LDU0Ljk2OSwyNy43MTYsNTQuNDk3LDI3LjUxMnoiLz48cGF0aCBkPSJNMC4zNjEsMTYuMTI1bDEzLjY2Miw1LjQ2NWwxMi41MzcsNS4wMTVjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMGwxMi41NDEtNS4wMTZsMTMuNjU4LTUuNDYzYzAuNDc3LTAuMTkxLDAuNDgtMC41MTEsMC4wMS0wLjcxNkwyOC4yNzcsNC4wNDhjLTAuNDcxLTAuMjA0LTEuMjM2LTAuMjA0LTEuNzA4LDBMMC4zNTEsMTUuNDFDLTAuMTIxLDE1LjYxNC0wLjExNiwxNS45MzUsMC4zNjEsMTYuMTI1eiIvPjwvZz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==) !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 70%;
        }
  .mapbox-improve-map {
      display: none;
  }
</style>